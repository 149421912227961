import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appDragDrop]',
    standalone: true,
})
export class DragDropDirective {
    @Output() fileDropped = new EventEmitter<FileList>();

    @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('drop', ['$event']) public onDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer && event.dataTransfer.files.length > 0) {
            this.fileDropped.emit(event.dataTransfer.files);
        }
    }
}
