import { Component, EventEmitter, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DragDropDirective } from './drag-drop.directive';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.css'],
    standalone: true,
    imports: [CommonModule, DragDropDirective, TranslocoModule],
})
export class ImageUploadComponent {
    imageSrc: any = '';
    file: File | null = null;
    @Output() fileDropped = new EventEmitter<FileList>();

    constructor(
        private sanitizer: DomSanitizer,
        private snackBar: MatSnackBar,
        public dialog: MatDialog
    ) {}

    onFileDropped(fileList: FileList) {
        this.handleFileInput(fileList);
    }

    handleFileInput(files: FileList) {
        if (files && files[0]) {
            this.file = files[0];
            const reader = new FileReader();
            this.fileDropped.emit(files);
        }
    }

    onFileSelected(event: any) {
        const files: FileList = event.target.files;
        this.handleFileInput(files);
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, 'Close', {
            duration: 2000,
        });
    }

    removeImage() {
        this.file = null;
        this.imageSrc = '';
    }
}
