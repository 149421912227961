<div
    *transloco="let t; read: 'imageUpload'"
    class="w-full max-w-lg mx-auto p-4 border-2 border-dashed border-gray-300 rounded-lg"
    matTooltip="Drag and drop an image or click to select one">
    <!-- Drag and Drop Zone -->
    <div
        appDragDrop
        (fileDropped)="onFileDropped($event)"
        class="relative flex justify-center items-center py-2 cursor-pointer">
        <div *ngIf="!imageSrc" class="text-center">
            <p class="text-sm text-gray-600">
                {{ t('dragAndDrop') }}
            </p>
            <p class="text-sm text-gray-600">
                {{ t('or') }}
            </p>
            <button
                type="button"
                class="bg-blue-500 text-white px-4 py-2 rounded"
                (click)="fileInput.click()">
                {{ t('selectFile') }}
            </button>
            <input
                #fileInput
                type="file"
                class="hidden"
                (change)="onFileSelected($event)"
                accept="image/*" />
        </div>

        <!-- Display the uploaded image -->
        <img
            *ngIf="imageSrc"
            [src]="imageSrc"
            class="max-w-full h-auto rounded-lg"
            alt="Uploaded Image" />
    </div>

    <!-- Remove image button -->
    <button
        *ngIf="imageSrc"
        class="mt-4 bg-red-500 text-white px-4 py-2 rounded"
        (click)="removeImage()">
        Remove Image
    </button>
</div>
